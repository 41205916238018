/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { computed } from '@ember/object';
import { addObserver, removeObserver } from '@ember/object/observers';
import { A } from '@ember/array';
import { reads } from '@ember/object/computed';
import { scheduleOnce, next } from '@ember/runloop';
import { flatten, each, compact } from 'lodash';
import { getOwner } from '@ember/application';
import { inject as service } from '@ember/service';

import MediaStore from 'mewe/stores/media-store';
import Scrolling from 'mewe/utils/scrolling-utils';
import MediafeedApi from 'mewe/api/mediafeed-api';
import PostApi from 'mewe/api/post-api';
import MyCloudApi from 'mewe/api/mycloud-api';
import PS from 'mewe/utils/pubsub';
import EnvironmentUtils from 'mewe/utils/environment-utils';
import { Post } from 'mewe/stores/models/post-model';
import JSONSerializer from 'mewe/utils/store-utils/serializers/json-serializer';
import isUndefined from 'mewe/utils/isUndefined';
import { openPostbox } from 'mewe/utils/dialogs-common';
import { photosToGrid } from 'mewe/utils/photo-grid-utils/photo-grid-util';
import { standarizePhotos } from 'mewe/utils/photo-grid-utils/utils';
import { getElHeight } from 'mewe/utils/elements-utils';
import { Theme } from 'mewe/constants';
import FunctionalUtils from 'mewe/shared/functional-utils';

export default class MwPhotoStream extends Component {
  @service analytics;
  @service dynamicDialogs;
  @service account;

  @reads('args.scope') scope;
  @reads('args.groupId') groupId;
  @reads('args.group') group;
  @reads('args.event') event;
  @reads('args.userId') userId;
  @reads('args.eventId') eventId;
  @reads('args.pageId') pageId;
  @reads('args.page') page;
  @reads('args.threadId') threadId;
  @reads('args.thread') thread;
  @reads('args.albumName') albumName;

  @tracked loaded;
  @tracked showReload;
  @tracked photoStreamRows;

  defaultMaxResults = 20;
  defaultOrder = 0;
  limitSelectedPhotos = 200;
  loadedPages = A();

  constructor() {
    super(...arguments);

    this.scrolling = Scrolling();

    this.globals = getOwner(this).lookup('globals:main');

    // Home sidebar stream is cached to don't load it every time Home is opened because it's heavy for db
    const mediaStoreState = MediaStore.getState();
    const isCachedStream = this.scope === 'contacts' && mediaStoreState.mwPhotoStreamData;

    if (isCachedStream) {
      this.photoStreamRows = mediaStoreState.mwPhotoStreamData.photoStreamRows;
      this.showReload = mediaStoreState.mwPhotoStreamData.showReload;
      this.nextPageUrl = mediaStoreState.mwPhotoStreamData.nextPageUrl;
      this.loaded = mediaStoreState.mwPhotoStreamData.loaded;

      this.scrolling.bindScrollDownElement(document.querySelector(this.args.containerSelector), () =>
        this.renderView()
      );

      MediaStore.send('cleanMwPhotoStream');
    } else {
      this.photoStreamRows = A();
      this.showReload = false;
      this.isShiftPressed = false;
      this.loaded = false;
    }

    if (!isCachedStream || this.updateInBackground) {
      if (this.args.fillContainerHeight) {
        scheduleOnce('afterRender', this, () => {
          if (this.isDestroyed || this.isDestroying) return;
          this.setMaxResultsFromHeightAndRenderView();
        });
      } else {
        this.renderView();
      }
    }

    addObserver(this, 'loaded', this, this.loadedChange);
    addObserver(this, 'order', this, this.orderChange);
    addObserver(this, 'threadId', this, this.threadIdChange);
    addObserver(this, 'selectedPhotos.length', this, this.selectedPhotosChange);
    addObserver(this, 'photoStreamPhotos.length', this, this.photostreamChange);

    this.bindPubSub();
  }

  @computed('args.maxResults', 'defaultMaxResults', 'increasedMaxResults')
  get maxResults() {
    return this.increasedMaxResults ?? this.args.maxResults ?? this.defaultMaxResults;
  }

  @computed('args.order', 'defaultOrder')
  get order() {
    return this.args.order ?? this.defaultOrder;
  }

  @action
  onInsert() {
    this.isShiftKeyEventBind = this.isShiftKeyEvent.bind(this);
  }

  @action
  onDestroy() {
    this.scrolling.unbindScrollDown(document.querySelector(this.args.containerSelector));
    this.scrolling.unbindScrollDown();

    PS.Unsub('photoStream.update', this.updateViewBind);
    PS.Unsub('photoAlbums.reload', this.photoAlbumsReloadBind);

    removeObserver(this, 'loaded', this, this.loadedChange);
    removeObserver(this, 'order', this, this.orderChange);
    removeObserver(this, 'threadId', this, this.threadIdChange);
    removeObserver(this, 'selectedPhotos.length', this, this.selectedPhotosChange);
    removeObserver(this, 'photoStreamPhotos.length', this, this.photostreamChange);

    if (this.scope === 'contacts' && this.photoStreamRows.length) {
      MediaStore.send('storeMwPhotoStream', {
        photoStreamRows: this.photoStreamRows,
        showReload: this.showReload,
        nextPageUrl: this.nextPageUrl,
        loaded: true,
      });
    }
  }

  bindScroll() {
    if (!this.args.dontBindScroll) {
      const containerEl = document.querySelector(this.args.containerSelector);

      if (this.args.containerSelector) {
        this.scrolling.bindScrollDownElement(containerEl, () => this.renderView());
      } else {
        this.scrolling.bindScrollDown(() => this.renderView());
      }
    }
  }

  checkShiftPressed() {
    const selectedPhotosCount = this.selectedPhotos.length;

    if (selectedPhotosCount) {
      // we need to detect pressed shift when user selects first photo
      if (selectedPhotosCount === 1) {
        document.addEventListener('keyup', this.isShiftKeyEventBind);
        document.addEventListener('keydown', this.isShiftKeyEventBind);
      }
    } else {
      // no photo selected, we don't need it
      document.removeEventListener('keyup', this.isShiftKeyEventBind);
      document.removeEventListener('keydown', this.isShiftKeyEventBind);
      this.isShiftPressed = false;
    }
  }

  loadedChange(value) {
    this.args.setLoadedState?.(value); // send state to parent
  }

  photostreamChange() {
    this.args.checkEmptyStream?.(this.photoStreamPhotos.length === 0 && this.loaded);
  }

  selectedPhotosChange() {
    this.checkShiftPressed();
  }

  orderChange() {
    this.reloadView();
  }

  threadIdChange() {
    this.reloadView();
  }

  bindPubSub() {
    this.updateViewBind = this.updateView.bind(this);
    PS.Sub('photoStream.update', this.updateViewBind);

    this.photoAlbumsReloadBind = this.photoAlbumsReload.bind(this);
    PS.Sub('photoAlbums.reload', this.photoAlbumsReloadBind);
  }

  photoAlbumsReload(albumName) {
    if (this.isAlbumPhotoStream && albumName && this.albumName === albumName) {
      this.reloadView();
    }
  }

  @computed('photoStreamRows.length', 'photoStreamRows.@each.length')
  get photoStreamPhotos() {
    return A(flatten(this.photoStreamRows));
  }

  @computed('photoStreamPhotos.@each.isSelected')
  get selectedPhotos() {
    return this.photoStreamPhotos.filterBy('isSelected');
  }

  @computed('albumName.length')
  get isAlbumPhotoStream() {
    return this.albumName?.length;
  }

  @computed('group.isOwnerAdmin', 'event.isOwner', 'isAlbumPhotoStream')
  get hidePhotoSelectors() {
    return this.isAlbumPhotoStream && !this.group?.isOwnerAdmin && !this.event?.isOwner;
  }

  @computed('selectedPhotos.length')
  get canDownload() {
    // SG-15968
    if (this.albumName?.length && this.scope === Theme.GROUP && this.selectedPhotos.length > 1) {
      return false;
    }

    return !this.selectedPhotos.find((p) => {
      return p.postType === 'video' || p.video || p.isVideo;
    });
  }

  @computed('scope', 'groupId', 'userId', 'group.canPost', 'page.isOwnerAdmin', 'event.canPost')
  get canPost() {
    switch (this.scope) {
      case Theme.GROUP:
        return this.group?.canPost;
      case Theme.PAGE:
        return this.page?.isOwnerAdmin;
      case Theme.EVENT:
        return this.event?.canPost;
      case Theme.PROFILE:
        return this.userId === this.account.activeUser.id; // only on own profile
      case Theme.MYCLOUD:
        return this.groupId === 'mycloud'; // not in a group view inside mycloud
      default:
        return false;
    }
  }

  @computed('args.containerSelector', 'scope', 'hidePhotoSelectors')
  get showPhotoSelectors() {
    return (
      this.scope !== Theme.PAGE &&
      this.scope !== Theme.GROUP &&
      this.scope !== Theme.EVENT &&
      this.scope !== Theme.CHAT &&
      this.scope !== Theme.PROFILE &&
      !this.args.containerSelector &&
      (this.scope !== Theme.PROFILE || !this.isAlbumPhotoStream) &&
      !this.hidePhotoSelectors
    );
  }

  @computed('selectedPhotos.length')
  get selectedPhotosText() {
    const selectedPhotosCount = this.selectedPhotos.length;
    return selectedPhotosCount ? __('Selected: {count} Photo/Video', { count: selectedPhotosCount }) : '';
  }

  @computed('scope')
  get placeholderSize() {
    return this.scope === Theme.CHAT ? 'auto' : '';
  }

  get isMyCloud() {
    return this.scope === Theme.MYCLOUD;
  }

  isShiftKeyEvent(e) {
    this.isShiftPressed = e.shiftKey;
  }

  renderView() {
    if (this.fetchingInProgress) return;

    const params = this.getFetchParams();

    const callback = (data) => {
      if (this.isDestroyed || this.isDestroying) return;

      this.fetchingInProgress = false;

      const nextPageLink = data._links?.nextPage?.href;

      if (data.safeMode && !data.feed.length) {
        FunctionalUtils.error(__(`We're sorry but we couldn't retrieve the posts. Please try again later.`));
      }

      const serializer = JSONSerializer.create();
      const PostThemed = Post.extend({ postScope: this.scope });
      const media = this.prepareMedia(data.feed || data.media);
      let posts = A();

      serializer.deserializeMany(posts, PostThemed, media);

      // if photostream was rerendered while some photos are selected, reselecting them
      // (usecase: Repost selected photos causes reRendering and we want to keep selected posts selected)
      if (this.selectedPhotos.length) {
        each(this.selectedPhotos, (selected) => {
          const postToSelect = posts.find((post) => post.mediaId === selected.mediaId);
          if (postToSelect) postToSelect.set('isSelected', true);
        });
      }

      // temporary hack as I can't figure out why computed property 'photoStreamPhotos' is not up to date
      // after setting 'photoStreamRows' in MW sidebar photostream
      next(this, () => {
        if (this.isDestroyed || this.isDestroying) return;

        if (this.updateInBackground) {
          this.photoStreamRows = A(this.createNewGrid(posts));
          this.updateInBackground = false;
        } else {
          this.photoStreamRows.pushObjects(this.createNewGrid(posts));
        }
      });

      if (!nextPageLink && !this.isAlbumPhotoStream) {
        this.loaded = true;
        this.nextPageUrl = null;
        return;
      }

      // postpone until pushedObjects will appear to avoid blinking empty placeholder
      next(this, () => {
        if (this.isDestroyed || this.isDestroying) return;

        this.loaded = true;
        this.nextPageUrl = nextPageLink;

        if (nextPageLink) {
          this.bindScroll();
        }
      });
    }; // end of callback

    if (!params.nextPage) {
      this.showReload = false; // unset when loading first page
    }

    if (!this.loadedPages.includes(params.nextPage)) {
      this.fetchingInProgress = true;

      this.loadedPages.push(params.nextPage);
      this.fetchData(params, callback);
    }
  }

  getFetchParams() {
    const params = {
      maxResults: this.maxResults,
      limit: this.maxResults,
      order: this.order,
      nextPage: this.nextPageUrl,
    };

    if (this.args.isImageSelector) {
      params.mediaTypes = 'photo';
    }

    if (this.isAlbumPhotoStream) {
      params.sizes = 1;
    }

    if (this.userId) {
      params.userId = this.userId;
    }

    return params;
  }

  fetchData(params, callback) {
    switch (this.scope) {
      case Theme.CHAT:
        if (this.threadId) {
          params.threadId = this.threadId;
          MediafeedApi.getGalleryDataForChatImg(params).then(callback);
        }
        break;

      case Theme.CONTACTS:
      case Theme.PROFILE:
        MediafeedApi.getMediaByScopeWithoutPosts(params, 'contacts', this.scope, this.albumName).then(callback);
        break;

      case Theme.PAGE:
        MediafeedApi.getMediaByScopeWithoutPosts(params, this.page?.id, this.scope, this.albumName).then(callback);
        break;

      default:
        let groupId = this.groupId;
        if (this.scope === Theme.EVENT) {
          groupId = this.eventId;

          if (groupId) {
            MediafeedApi.getMediaByScopeWithoutPosts(params, groupId, this.scope, this.albumName).then(callback);
          }
        } else {
          MediafeedApi.getMediaByScopeWithoutPosts(params, groupId, this.scope, this.albumName).then(callback);
        }

        break;
    }
  }

  prepareMedia(media = []) {
    const addEventData = this.scope === Theme.EVENT;

    return compact(
      media.map((photo) => {
        if (this.args.isImageSelector && photo.postType === 'video') return;

        photo = this.processStreamItem(photo);

        //populate event data - since it's not returned from backend
        if (addEventData && !photo.event2) {
          photo.event2 = { id: this.eventId };
        }

        return photo;
      })
    );
  }

  updateView(options = {}) {
    if (!options.type) return;

    if (options.type === 'remove') {
      this.removedPhotoUpdate(options);
    } else if (options.type === 'add') {
      this.addedPhotoUpdate(options);
    }
  }

  removedPhotoUpdate(options = {}) {
    if (!this.photoStreamPhotos.length || (!options.removeIds && !options.post && !options.message)) return;

    let removedPhoto;

    // deleted photos selected in photo stream
    if (options.removeIds) {
      each(options.removeIds, (id) => {
        removedPhoto = this.photoStreamPhotos.find((p) => this.getProperPhotoId(p) === id);
        if (removedPhoto) this.handleRemovedPhoto(removedPhoto);
      });
    }
    // deleted post from: feed, in MD
    else if (options.post) {
      // if mediasCount > 4 we dont have all mediaIds so only 4 photos will be marked as deleted

      if (options.post?.medias?.length) {
        each(options.post.medias, (media) => {
          removedPhoto = this.photoStreamPhotos.find((p) => p.mediaId === media.mediaId);
          if (removedPhoto) this.handleRemovedPhoto(removedPhoto);
        });
      }
    }
    // deleted chat photo message, update photostream is chat info panel
    else if (options.message?.attachments?.length) {
      removedPhoto = this.photoStreamPhotos.find((p) => p.photo.id === options.message.attachments[0].fileObjectId);
      if (removedPhoto) this.handleRemovedPhoto(removedPhoto);
    }
  }

  handleRemovedPhoto(removedPhoto) {
    removedPhoto.set('isDeleted', true);
    this.showReload = true;
  }

  addedPhotoUpdate(options = {}) {
    const post = options.post;
    const scopeId = post.contacts ? 'contacts' : post.eventId || post.groupId || post.pageId || 'contacts';
    const currentScopeId = this.eventId || this.groupId || this.page?.id || this.threadId || this.scope;
    const myPostInMC = currentScopeId === 'mycloud' && post.userId === this.account.activeUser.id;
    const isCurrentProfile = post.userId === this.userId;

    if (currentScopeId === scopeId || myPostInMC || isCurrentProfile) {
      this.showReload = true;
    }
  }

  processStreamItem(p) {
    let photoObj = p.image || p.photo;

    const photoSize = this.args.sidebar ? '400x400' : '800x800'; // SG-15479
    const photoObjSizeOrDefault = (photoObj && photoObj.size) || { width: 300, height: 245 };

    if (photoObj && photoObj._links) {
      if (p.postType === 'video') {
        p.imgUrl = photoObj._links.img.href.replace('{imageSize}', '400x400').replace('{static}', '1');
      } else {
        p.imgUrl = photoObj._links.img.href.replace('{imageSize}', photoSize).replace('{static}', '1');

        if (p.photo) {
          p.photo.size = photoObjSizeOrDefault;
        } else {
          p.photo = {
            size: photoObjSizeOrDefault,
          };
        }
      }
    } else if (p.video) {
      // chat video
      p.imgUrl = '';
    }

    return p;
  }

  createNewGrid(photos) {
    let targetHeight = this.targetHeight;
    return photosToGrid(photos.map(standarizePhotos(targetHeight)), targetHeight, this.args.containerWidth);
  }

  @computed('args.targetHeight', 'args.containerWidth')
  get targetHeight() {
    return this.args.targetHeight ?? this.args.containerWidth / 3.75;
  }

  selectPhoto(photo, markAs) {
    const stateNotGiven = isUndefined(markAs);
    const setState = stateNotGiven ? !photo.isSelected : markAs;

    if (this.isShiftPressed && stateNotGiven) {
      this.selectPhotosToLastSelected(photo);
      return;
    }

    if (setState === true && this.selectedPhotos.length >= this.limitSelectedPhotos) {
      this.dynamicDialogs.openDialog('simple-dialog-new', {
        title: __('Too many photos'),
        message: __(`Sorry, you've reached the limit. You can select a max of {count} photo at once.`, {
          count: this.limitSelectedPhotos,
        }),
        okButtonText: __('OK'),
        allowMultipleInstances: false,
      });
    } else {
      photo.set('isSelected', setState);
      // used for SHIFT+click multiple selection
      this.lastSelectedPhotoIndex = this.photoStreamPhotos.indexOf(photo);
    }

    return false;
  }

  selectPhotosToLastSelected(photo) {
    const markAs = photo.isSelected ? false : true,
      photos = this.photoStreamPhotos,
      lastClickIndex = this.lastSelectedPhotoIndex,
      clickIndex = photos.indexOf(photo),
      photosInRange =
        lastClickIndex > clickIndex
          ? photos.slice(clickIndex, lastClickIndex + 1)
          : photos.slice(lastClickIndex, clickIndex + 1);

    // first photo clicked with shift, single select
    if (isUndefined(this.lastSelectedPhotoIndex)) {
      this.selectPhoto(photo, markAs);
    } else {
      each(photosInRange, (p) => {
        this.selectPhoto(p, markAs);
      });
    }

    this.lastSelectedPhotoIndex = clickIndex;
  }

  setMaxResultsFromHeightAndRenderView() {
    const containerEl = document.querySelector('.photo-stream').parentNode,
      estimatedRowHeight = 100,
      estimatedPhotosPerRow = 4,
      estimatedRows = Math.ceil(this.maxResults / estimatedPhotosPerRow, 10),
      estimatedHeight = estimatedRows * estimatedRowHeight;

    if (getElHeight(containerEl) > estimatedHeight) {
      scheduleOnce('afterRender', this, () => {
        if (this.isDestroyed || this.isDestroying) return;

        const newMax = Math.ceil(
          (getElHeight(containerEl) * estimatedRows * estimatedPhotosPerRow) / estimatedHeight,
          10
        );

        if (newMax > this.maxResults) {
          this.increasedMaxResults = newMax;
        }

        this.renderView();
      });
    } else this.renderView();
  }

  getProperPhotoId(p) {
    // MC: in case of group media in MC 'mediaId' should be used to delete completely
    // in other cases it should be 'id' (SG-20177)
    if (this.scope === Theme.MYCLOUD) {
      return p.mediaId || p.id;
    } else {
      return p.postId || p.id || p.mediaId;
    }
  }

  @action
  openPostboxDialog(target) {
    // if target is not passed then click event is passed
    if (typeof target !== 'string') {
      target = undefined;
    }

    let params = {
      openWithPhotoBrowser: isUndefined(target),
      selectedAlbumName: this.albumName,
      target: target,
    };

    if (!params.target) {
      switch (this.scope) {
        case Theme.GROUP:
          params.preselectedGroup = this.group;
          params.target = Theme.GROUP;
          break;

        case Theme.EVENT:
          params.preselectedEvent = this.event;
          params.target = Theme.EVENT;
          break;

        case Theme.PAGE:
          params.page = this.page;
          params.target = Theme.PAGE;
          break;

        case Theme.MYCLOUD:
          params.myCloudUploading = true;
          params.target = Theme.MYCLOUD;
          break;

        default:
          params.target = Theme.CONTACTS;
      }
    }

    params.theme = params.target;

    params.preselectedPhotos = this.selectedPhotos.map((photo) => {
      return {
        id: photo.photo.id,
        tempId: photo.photo.id, // need to be able to change order of photos in postbox (MW)
        existing: true,
        orientation: 'normal',
        url: photo.imgUrl || photo.imageLink,
        isUrl: true,
        video: photo.video,
        mediaId: photo.mediaId,
      };
    });

    openPostbox(params, this.dynamicDialogs);
  }

  @action
  unselectPhotos() {
    each(this.photoStreamPhotos, (p) => {
      p.set('isSelected', false);
    });
  }

  @action
  reloadView() {
    if (this.isDestroyed || this.isDestroying) return;
    if (this.fetchingInProgress) return;

    this.loaded = false;
    this.photoStreamRows = A();
    this.loadedPages = A();
    this.nextPageUrl = null;

    this.renderView();
  }

  @action
  downloadMedia() {
    let selectedPhotos = this.selectedPhotos;

    if (selectedPhotos) {
      if (selectedPhotos.length === 1) {
        let photo = selectedPhotos[0].photo;
        let link = '';

        if (photo && photo._links) {
          link = photo._links.img.href.replace('{imageSize}', 'full');
        } else {
          photo = selectedPhotos[0].image;

          if (photo && photo._links) {
            link = photo._links.img.href.replace('{imageSize}', 'full');
          }
        }

        link = link.replace('?static=1', '').replace('?static={static}', '');

        if (!link.toLowerCase().match(/\.(jpg|png|gif)/g)) {
          link += '.jpg';
        }

        window.location = EnvironmentUtils.getImgHost(true) + link + '/download';
      } else {
        let ids = A();

        each(selectedPhotos, (p) => {
          if (p && p.image && p.image.id) ids.push(p.image.id);
          else if (p && p.photo && p.photo.id) ids.push(p.photo.id);
        });

        if (ids && ids.length) {
          window.location = '/api/v2/mycloud/download?photoIds=' + ids.join(',');
        }
      }

      if (this.isMyCloud) {
        this.analytics.sendEvent('photoDownloaded', {
          location: 'cloud',
          is_owner: true,
        });
      }
    }
  }

  @action
  deleteMedia() {
    const callback = (ids) => {
      this.removedPhotoUpdate({ removeIds: ids });
      this.unselectPhotos();
    };

    if (this.selectedPhotos.length) {
      const isDeletingFromMC = this.scope === Theme.MYCLOUD;

      // batch removal
      const ids = this.selectedPhotos.map((p) => this.getProperPhotoId(p));

      this.dynamicDialogs.openDialog('simple-dialog-new', {
        title: __('Confirm Deletion'),
        message: isDeletingFromMC
          ? __(
              'You will delete this {count} photo from everywhere, including My Cloud and any group or album that it was shared with.',
              {
                count: this.selectedPhotos.length,
              }
            )
          : __('This will remove this {count} photo from the album.', {
              count: this.selectedPhotos.length,
            }),
        okButtonText: isDeletingFromMC ? __('Delete from everywhere') : __('Remove'),
        cancelButtonText: __('Abort'),
        onConfirm: () => {
          if (this.isDestroying || this.isDestroyed) return;

          if (!isDeletingFromMC) {
            PostApi.removePostsFromAlbum({
              scope: this.scope,
              albumName: this.albumName,
              postItemIds: ids,
              groupId: this.groupId,
              eventId: this.eventId,
              pageId: this.page?.id || this.pageId,
            }).then(() => callback(ids));
          } else {
            MyCloudApi.deleteMedia(JSON.stringify({ mediaIds: ids })).then(() => callback(ids));
          }
        },
      });
    }
  }

  @action
  openMediaDialog(post, e) {
    if (post.isDeleted) return;

    if (this.selectedPhotos.length || e.target.closest('.photo-el-select')) {
      this.selectPhoto(post);
      return false;
    }

    if (this.args.isImageSelector) {
      this.args.selectProfilePhoto(this.loader, post);
      return false;
    }

    let previewImgUrl = '';
    let params = {};

    if (post.imgUrl) {
      previewImgUrl =
        EnvironmentUtils.getImgHost(true) +
        post.imgUrl.replace('{imageSize}', `${post.resized?.width}x${post.resized?.height}`);
    }

    if (this.scope === Theme.CHAT) {
      params = {
        attachment: post,
        chatGallery: true,
        mediaType: 'chat',
        order: 0,
        threadId: this.threadId,
        thread: this.thread,
        previewImgUrl,
      };
    } else {
      let groupId,
        eventId,
        postScope = post.postScope;

      if (
        postScope === Theme.GROUP ||
        (this.scope === Theme.MYCLOUD &&
          this.groupId &&
          this.groupId !== Theme.CONTACTS &&
          this.groupId !== Theme.MYCLOUD)
      ) {
        groupId = this.groupId;
        postScope = 'group';
      } else if (postScope === Theme.EVENT) {
        eventId = this.eventId;
      } else if (postScope === Theme.MYCLOUD) {
        groupId = Theme.MYCLOUD;
      } else if (postScope === Theme.PAGE) {
        groupId = null;
      } else {
        groupId = Theme.CONTACTS;
      }

      params = {
        postId: post.postItemId || post.id || post.postId || post.mediaId,
        groupId: groupId,
        eventId: eventId,
        pageId: this.page?.id || post.pageId,
        page: this.page,
        scope: postScope,
        post,
        imageSize: post.photo?.size,
        previewImgUrl,
      };

      if (this.args.albumViewMenu) {
        params.mediaType = 'album';
        params.album = this.albumName;
        params.userId = this.userId || post.userId;
        params.order = this.order;
      } else {
        if (this.userId) params.userId = this.userId || post.userId;
        if (this.type === 'user-stream') params.groupId = 'contacts';
      }

      if (this.isMyCloud) {
        params.isMyCloud = true;
        params.theme = 'mycloud';
      }

      if (post.video) {
        params.post = {
          ...post,
          medias: {},
          video: {
            sources: [
              {
                src: post.video?._links?.linkTemplate?.href?.replace('{resolution}', 'original'),
                type: 'video/mp4',
              },
            ],
          },
        };

        params.post.video = {
          ...params.post.video,
          _links: {
            ...params.post.video._links,
            img: {
              href: post.video?._links?.linkTemplate?.href,
            },
            linkTemplate: {
              href: post.video?._links?.linkTemplate?.href,
            },
          },
        };
        params.isVideoPost = true;
        delete params.previewImgUrl;
      }
    }

    params.allowMultipleInstances = true;

    this.dynamicDialogs.openDialog('media-dialog', params);
  }
}
